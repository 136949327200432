import React from "react";
import PropTypes from "prop-types";

import Heart from "../icons/Heart";
import defaultAvatar from "../images/bg-default-avatar.png";

export const LikeToast = ({ likeImage }) => (
  <div style={{ alignItems: "center", justifyContent: "center" }}>
    <div style={{ alignItems: "center", justifyContent: "center" }}>
      <img
        alt="toast-data"
        src={likeImage.photoPath}
        style={{ height: 75, width: 75, borderRadius: 75 }}
      />
      <div
        style={{
          position: "absolute",
          bottom: 15,
          right: 10,
          borderRadius: 25,
          width: 25,
          height: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red",
          border: "1px solid rgba(0, 0, 0, 0.05)",
        }}
      >
        <Heart width={17} height={17} color="white" />
      </div>
    </div>
  </div>
);

LikeToast.propTypes = {
  likeImage: PropTypes.shape({
    attendee: PropTypes.shape({
      profilePhoto: PropTypes.string,
      username: PropTypes.string,
    }),
    text: PropTypes.string,
    photoPath: PropTypes.string,
  }),
};

export const CommentToast = ({ commentImage }) => (
  <div>
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        style={{
          flex: 1,
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 3,
          }}
        >
          <img
            alt="profile-picture"
            src={commentImage.attendee.profilePhoto ?? defaultAvatar}
            style={{ height: 25, width: 25, borderRadius: 25, marginRight: 5 }}
          />
          <span style={{ fontWeight: "bold", color: "black", marginTop: 2 }}>
            {commentImage.attendee.username ?? "Anonymous"}
          </span>
        </div>
        <span style={{ color: "black", textAlign: "left" }}>
          {commentImage.text.substring(0, 50).trim()}
          {commentImage.text.length > 50 ? "..." : ""}
        </span>
      </div>
      <div style={{ alignItems: "center", justifyContent: "center" }}>
        <img
          alt="toast-data"
          src={commentImage.photoPath}
          style={{ height: 75, width: 75, borderRadius: 75 }}
        />
      </div>
    </div>
  </div>
);

CommentToast.propTypes = {
  commentImage: PropTypes.shape({
    attendee: PropTypes.shape({
      profilePhoto: PropTypes.string,
      username: PropTypes.string,
    }),
    text: PropTypes.string,
    photoPath: PropTypes.string,
  }),
};
