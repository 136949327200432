// import vendors
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// import locales
import * as resources from "./resources";

// Setup
const initI18n = () =>
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      fallbackLng: "fr",
      returnObjects: true,
      interpolation: { escapeValue: false },
      resources: {
        ...Object.entries(resources).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: {
              translation: value,
            },
          }),
          {}
        ),
      },
    });

export default initI18n;
