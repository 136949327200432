import React, { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import QRCodeStyling from "qr-code-styling";
import { useTranslation } from "react-i18next";

import Logo from "../icons/LogoBlanc";
// import LogoImg from "../images/logoBlanc.svg";

const DownloadJoinIncentive = ({ eventCode }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const url = useMemo(
    () => `https://api.clickty.io/event/join/${eventCode}`,
    [eventCode]
  );

  const qRCodeHeight = useMemo(() => Math.round(window.innerHeight * 0.15), []);

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: qRCodeHeight,
        height: qRCodeHeight,
        data: url,
        margin: 0,
      }),
    [url]
  );

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 10,
        left: 10,
        display: "flex",
        flexDirection: "column",
        // maxWidth: "20%",
        alignItems: "center",
        backgroundColor: "black",
        borderRadius: 10,
        padding: 10,
        paddingBottom: 0,
      }}
    >
      <div ref={ref} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <h5 style={{ color: "white", textAlign: "center", marginRight: 10 }}>
            {t("diaporama.download")}
          </h5>
          <Logo height={50} width={50} />
        </div>
        <h5 style={{ color: "white", textAlign: "center" }}>
          {t("diaporama.join")}
        </h5>
        <p style={{ color: "white", textAlign: "center", maxWidth: 250 }}>
          {t("diaporama.findUs")}
        </p>
      </div>
    </div>
  );
};

DownloadJoinIncentive.propTypes = {
  eventCode: PropTypes.string,
};

export default DownloadJoinIncentive;
