import React from "react";
import PropTypes from "prop-types";

const Heart = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    width={width}
    height={height}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={color}
      d="M10 366c0-75.2 27.1-139.5 81.3-192.8 54.2-53.4 118.1-80 191.6-80 92.2 0 166.2 38.4 222 115.2 25.9-35.6 57.6-63.7 95.2-84.3 37.6-20.6 76.6-30.9 117-30.9 76 0 140.5 26.5 193.5 79.4 53 53 79.4 117.4 79.4 193.5 0 104.3-33.6 190-100.7 257.1-24.3 25.1-65.3 59.4-123.1 103.1-57.8 43.7-111.4 82.7-160.7 117-49.3 34.4-74.4 52-75.2 52.8-14.6 7.3-24.7 10.9-30.3 10.9-5.7 0-15.8-3.6-30.3-10.9-1.6-1.6-26.9-19.6-75.8-54S291.6 768.7 233.8 725C176 681.3 135 647.4 110.7 623.1 43.6 556 10 470.3 10 366z"
    />
  </svg>
);

Heart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Heart;
