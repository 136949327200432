import React from "react";
import PropTypes from "prop-types";

const Screen = ({ width, height, color, ...props }) => (
  <svg
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
    {...props}
  >
    <path
      fill={color}
      d="M28 4H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8v4H8v2h16v-2h-4v-4h8a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM18 28h-4v-4h4Zm10-6H4V6h24Z"
    />
    <path fill="none" d="M0 0h32v32H0z" />
  </svg>
);

Screen.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Screen;
