import GLSlideshow from "../GLSlideshow";

import ButterflyScrawler from "./ButterflyScrawler.glsl";
import RotateScaleFade from "./RotateScaleFade.glsl";
import Perlin from "./Perlin.glsl";
import Doorway from "./Doorway.glsl";
import Swap from "./Swap.glsl";
import WindowSlice from "./WindowSlice.glsl";
import CrossWarp from "./CrossWarp.glsl";

GLSlideshow.addShader("ButterflyScrawler", ButterflyScrawler, {
  amplitude: 1.0,
  waves: 30.0,
  colorSeparation: 0.3,
});
GLSlideshow.addShader("RotateScaleFade", RotateScaleFade);
GLSlideshow.addShader("Perlin", Perlin, {
  scale: 4.0,
  smoothness: 0.01,
  see: 12.9898,
});
GLSlideshow.addShader("Doorway", Doorway, {
  reflection: 0.4,
  perspective: 0.4,
  depth: 3,
});
GLSlideshow.addShader("Swap", Swap, {
  reflection: 0.4,
  perspective: 0.2,
  depth: 3.0,
});
GLSlideshow.addShader("WindowSlice", WindowSlice, {
  count: 10.0,
  smoothness: 0.5,
});
GLSlideshow.addShader("CrossWarp", CrossWarp);
