import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

const getOutsideValue = (x) => {
  const isNegative = Math.random() < 0.5;
  const randX = Math.floor(Math.random() * x);
  return isNegative ? -randX - 20 : 100 + randX + 20;
};

const randAngle = (range) => {
  return Math.round(Math.random() * range * 2 - range);
};

const getTransform = (angle, scale) => {
  return `translate3d(0, 0, 0) translate(-50%,-50%) rotate(${angle}deg) scale(${scale})`;
};

const getRandomOutsidePosition = (isStacked) => {
  const isSide = Math.random() < 1 / 2; // chances to come from a side only
  const isBoth = Math.random() < 1 / 8; // chances to come from both sides at the same time

  return {
    top: `${!isSide && !isBoth ? getInsideValue() : getOutsideValue(50)}%`,
    left: `${isSide && !isBoth ? getInsideValue() : getOutsideValue(50)}%`,
    transform: getTransform(randAngle(90), isStacked ? 1 : 0.5),
  };
};

const getInsideValue = () => 10 + Math.floor(Math.random() * 80); // between 15 and 85%

const getRandomInsidePosition = (isStacked) => {
  return {
    top: `${isStacked ? 50 : getInsideValue()}%`,
    left: `${isStacked ? 50 : getInsideValue()}%`,
    transform: getTransform(
      randAngle(isStacked ? 15 : 40),
      isStacked ? 1 : 0.5
    ),
  };
};

export const Polaroid = ({
  src,
  description,
  hide = false,
  isStacked = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { startPosition, endPosition } = useMemo(() => {
    const start = getRandomOutsidePosition(isStacked);
    return {
      startPosition: start,
      endPosition: hide ? start : getRandomInsidePosition(isStacked),
    };
  }, [isStacked, hide]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const unit = window.innerWidth > window.innerHeight ? "vh" : "vw";

  const photoSize = {
    width: `40${unit}`,
  };

  const photoStyle = {
    ...photoSize,
    aspectRatio: "3/4",
    objectFit: "cover",
    overflow: "hidden",
    content: "",
  };

  const containerStyle = {
    zIndex: 1,
    display: "flex",
    padding: `4${unit}`,
    paddingBottom: "0px",
    flexDirection: "column",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.6)",
    position: "absolute",
    ...startPosition,
    transition: "top 2s, left 2s, transform 1.5s",
    transitionTimeFunction: "ease-out",
    transformOrigin: "center",
    opacity: isVisible ? 1 : 0,
    backgroundImage: "url(polaroid-bg.png)",
    backgroundRepeat: "repeat",
  };

  const visibleStyle = {
    ...containerStyle,
    ...endPosition,
  };

  const descriptionStyle = {
    ...photoSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Permanent Marker', cursive",
    fontSize: `2.5${unit}`,
    boxSizing: "border-box",
    padding: `2${unit}`,
    textAlign: "center",
    fonWeight: "bold",
    color: "rgba(20, 20, 20, 0.8)",
  };

  return (
    <div style={isVisible ? visibleStyle : containerStyle}>
      <img src={src} style={photoStyle} />
      <div style={descriptionStyle}>{description}</div>
    </div>
  );
};

Polaroid.propTypes = {
  src: PropTypes.string,
  description: PropTypes.string,
  hide: PropTypes.bool,
  isStacked: PropTypes.bool,
};
