import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import io from "socket.io-client";
import { useParams } from "react-router-dom";
import { QrCode } from "../components/QrCode";
import FullscreenToggle from "../components/FullscreenToggle";

const transitions = [
  {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1.2 },
  },
  {
    initial: { opacity: 0, x: -1000 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 1000 },
  },
  {
    initial: { opacity: 0, y: -1000 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 1000 },
  },
  {
    initial: { opacity: 0, rotate: -180 },
    animate: { opacity: 1, rotate: 0 },
    exit: { opacity: 0, rotate: 180 },
  },
  {
    initial: { opacity: 0, scale: 1.5 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
  },
  // Effet cube 3D - La photo tourne comme une face de cube
  {
    initial: { rotateY: 90, z: -1000, opacity: 0 }, // Commence tourné à 90° et en arrière
    animate: { rotateY: 0, z: 0, opacity: 1 }, // Revient face à l'utilisateur
    exit: { rotateY: -90, z: -1000, opacity: 0 }, // Sort en tournant dans l'autre sens
  },
  // Effet flip vertical - La photo fait une rotation complète sur elle-même
  {
    initial: { rotateX: 180, z: -500, opacity: 0 }, // Commence retournée verticalement
    animate: { rotateX: 0, z: 0, opacity: 1 }, // Revient à plat
    exit: { rotateX: -180, z: -500, opacity: 0 }, // Sort en faisant un flip arrière
  },
  // Effet tourbillon - Combine rotation et échelle pour un effet spirale
  {
    initial: { rotate: 180, scale: 0, z: -1000, opacity: 0 }, // Commence petit et tourné
    animate: { rotate: 0, scale: 1, z: 0, opacity: 1 }, // Grandit en tournant
    exit: { rotate: -180, scale: 0, z: -1000, opacity: 0 }, // Disparaît en spirale
  },
  // Effet porte - Simule une porte qui pivote sur ses gonds
  {
    initial: { rotateY: -90, x: "-50%", opacity: 0 }, // Commence comme une porte fermée
    animate: { rotateY: 0, x: 0, opacity: 1 }, // S'ouvre vers l'utilisateur
    exit: { rotateY: 90, x: "50%", opacity: 0 }, // Sort en continuant la rotation
  },
  // Effet zoom 3D - Zoom avant/arrière avec effet de profondeur
  {
    initial: { scale: 2, z: 1000, opacity: 0 }, // Commence grand et loin
    animate: { scale: 1, z: 0, opacity: 1 }, // Revient à la taille normale
    exit: { scale: 0, z: -1000, opacity: 0 }, // Sort en rétrécissant vers l'arrière
  },
];

const Motion = () => {
  // Récupération du code depuis les paramètres d'URL
  const { code } = useParams();

  // États locaux
  const [currentPhoto, setCurrentPhoto] = useState(null); // Photo actuelle
  const [isReady, setIsReady] = useState(false); // État de chargement
  const socket = useRef(null); // Référence au socket
  const [currentTransition, setCurrentTransition] = useState(0); // Index de la transition actuelle
  const [eventInfo, setEventInfo] = useState(null);
  const [currentPhotoId, setCurrentPhotoId] = useState(null);
  const [eventNotFound, setEventNotFound] = useState(false);

  const unit = window.innerWidth > window.innerHeight ? "vh" : "vw";

  // Effet pour initialiser la connexion socket
  useEffect(() => {
    const host =
      location.hostname === "localhost"
        ? "https://slideshow.clickty.synovia.fr"
        : window.location.host;
    // Établissement de la connexion WebSocket
    socket.current = io(`${host}/events?code=${code}`, {
      transports: ["websocket"],
      reconnection: true,
    });

    // Ajout du nouvel écouteur
    socket.current.on("event_not_found", () => {
      setEventNotFound(true);
      setIsReady(true);
    });

    // Écouteurs d'vénements
    socket.current.on("next_photo", onNewImage);
    socket.current.on("event_info", onEventInfo);
    socket.current.on("disconnect", handleDisconnect);

    // Nettoyage à la destruction du composant
    return () => {
      socket.current.disconnect();
      socket.current = null;
    };
  }, [code]);

  const onEventInfo = (event) => {
    setEventInfo(event);
  };

  // Gestionnaire pour nouvelle image reçue
  const onNewImage = (photo) => {
    setCurrentPhotoId(photo.id);
    setCurrentPhoto(photo);
    setCurrentTransition(Math.floor(Math.random() * transitions.length));
    setIsReady(true);
  };

  useEffect(() => {
    if (currentPhotoId && eventInfo) {
      socket.current?.emit("fb-screen", {
        event: "PRE-LOAD-OK",
        id: currentPhotoId,
        eventId: eventInfo.eventId,
      });
    }
  }, [currentPhotoId, eventInfo]);

  // Gestion de la déconnexion
  const handleDisconnect = (reason) => {
    if (reason === "io server disconnect") {
      socket.current.connect();
    }
  };

  // Affichage du message d'erreur si l'événement n'est pas trouvé
  if (isReady && eventNotFound) {
    return (
      <div
        className="w-screen h-screen bg-black"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <div
          className="text-white text-center"
          style={{
            transform: "translate(0, -10%)", // Léger ajustement optique vers le haut
          }}
        >
          <h1
            style={{
              fontSize: "5rem",
              marginBottom: "2rem",
              fontWeight: "bold",
            }}
          >
            Événement non trouvé
          </h1>
          <p
            style={{
              fontSize: "2rem",
              opacity: 0.8,
            }}
          >
            Event code not valid :-(.
          </p>
        </div>
      </div>
    );
  }

  // Affichage du chargement
  if (!isReady) {
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-black text-white text-2xl">
        Chargement des images...
      </div>
    );
  }

  // Rendu principal avec animations
  return (
    <div className="relative">
      <div
        className="position-relative vh-100 vw-100 bg-black d-flex align-items-center justify-content-center"
        style={{ perspective: "1200px" }}
      >
        {" "}
        {/* Perspective pour effets 3D */}
        <FullscreenToggle
          className={{
            position: "absolute",
            width: `10${unit}`,
            height: `10${unit}`,
            top: `3${unit}`,
            right: `5${unit}`,
            opacity: "0.7",
            cursor: "pointer",
            zIndex: 1000,
          }}
          onFullscreenChange={() => {}}
        />
        <div
          className="position-absolute bottom-0 start-0 m-4"
          style={{ zIndex: 1000 }}
        >
          {" "}
          {/* Ajout du z-index pour être au premier plan */}
          <QrCode
            eventCode={eventInfo?.shortCode}
            width={
              window.innerWidth > window.innerHeight
                ? window.innerHeight / 5
                : window.innerWidth / 5
            }
          />
        </div>
        <AnimatePresence mode="wait">
          {" "}
          {/* Gestion des animations de transition */}
          {currentPhoto && (
            <motion.div
              key={currentPhoto.id}
              className="w-100 h-100 position-relative"
              style={{
                transformStyle: "preserve-3d", // Support des transformations 3D
              }}
              // Application des propriétés d'animation de la transition courante
              initial={transitions[currentTransition].initial}
              animate={{
                ...transitions[currentTransition].animate,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
              exit={{
                ...transitions[currentTransition].exit,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
            >
              <img
                src={currentPhoto.url}
                className="w-100 h-100"
                style={{
                  objectFit: "contain",
                  backfaceVisibility: "hidden", // Évite les artefacts pendant les rotations 3D
                }}
                alt=""
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Motion;
