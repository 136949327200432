import React from "react";
import PropTypes from "prop-types";

const NoNetwork = ({ width, height, color, ...props }) => (
  <svg
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1058 1024"
    width={width}
    height={height}
    {...props}
  >
    <path
      fill={color}
      d="M532.9 846.895c-36.163 0-64.582 28.418-64.582 64.581 0 34.87 28.419 63.29 64.582 63.29s64.582-28.42 64.582-63.29c0-36.176-29.711-64.581-64.582-64.581zM132.48 224.31c-41.335 24.54-81.377 52.959-118.833 85.243-16.795 14.21-18.088 40.042-3.879 56.837 14.21 16.795 40.042 18.088 56.837 3.88 38.75-33.591 80.084-61.997 124.005-86.55l-58.13-59.41zm911.916 85.243C901.023 185.561 718.901 117.101 529.021 117.101c-81.376 0-162.753 12.916-238.959 37.456l64.582 65.875c56.837-14.21 116.247-21.954 175.67-21.954 170.499 0 334.546 60.703 462.417 171.791 7.757 6.452 16.795 10.33 25.832 10.33 11.624 0 21.954-5.171 31.005-14.209 12.916-16.795 11.623-42.628-5.172-56.837zm-738.831 90.428c-52.959 23.246-103.331 54.25-147.252 94.293-16.782 14.21-18.075 40.042-2.586 56.837 15.502 16.782 40.042 18.088 56.837 2.586 45.214-40.042 98.172-71.046 153.704-91.708l-60.703-62.008zM522.57 356.06c-11.624 0-23.247 0-34.87 1.293l80.083 81.377c98.173 9.05 191.173 50.385 266.085 116.247 7.745 6.465 16.795 10.33 27.126 10.33 11.623 0 21.954-5.171 29.712-12.916 15.502-16.782 12.916-42.615-2.586-56.837C787.362 405.139 656.905 356.06 522.57 356.06zm65.875 333.252l-87.83-89.121c-68.46 5.171-134.335 32.297-186 80.084-16.795 15.502-18.075 40.042-2.586 56.837 15.502 16.782 40.042 18.075 56.837 2.586 42.615-38.75 96.88-59.41 153.704-59.41 23.247-.026 45.2 2.56 65.875 9.024zM115.698 57.678c-10.33-10.33-25.833-10.33-34.883-1.293-9.038 9.05-10.33 24.54 0 33.59L783.483 805.56c5.172 5.171 11.623 7.757 16.782 7.757 6.465 0 11.624-2.586 16.795-6.464 9.038-9.038 10.33-24.54 0-33.578L115.698 57.678zm0 0"
    />
  </svg>
);

NoNetwork.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default NoNetwork;
