// SwitchIcon.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const SwitchMode = ({ code, mode, style }) => {
  const navigate = useNavigate();

  // Fonction appelée au clic sur l'image
  const handleClick = () => {
    navigate(`/${mode}/${code}`); // Redirection vers la nouvelle page
  };

  return (
    <div style={style}>
      {/* Bouton avec une image */}
      <button
        className="btn p-0 border-0"
        onClick={handleClick}
        style={{ background: "none" }} // Suppression du fond du bouton pour n'afficher que l'image
      >
        <img
          src="/slider-icon.png"
          alt="Redirection"
          style={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }} // Ajuster la taille selon tes besoins
        />
      </button>
    </div>
  );
};

// Validation des props
SwitchMode.propTypes = {
  code: PropTypes.string,
  mode: PropTypes.string,
  style: PropTypes.any,
};

export default SwitchMode;
