export default {
  appTitle: "Clickty",
  404: {
    title: "Erreur 404",
    cannotLaunch: "Impossible de charger la page :(",
    notFound: "Page non trouvée - veuillez bien vérifier",
    back: "RETOUR",
  },
  diaporama: {
    noData: "Il n'y a pas de photo pour le moment",
    badNetwork:
      "La connexion internet d'au moins un écran n'est pas optimale. Le délai entre les images sera ajusté pour vous offrir la meilleure expérience possible.",
    errors: {
      NOT_FOUND: {
        title: "Événement introuvable",
        subtitle:
          "Nous ne trouvons pas votre événement, peut-être que vous avez fait une faute de frappe ou qu'il est terminé.",
      },
      FINISHED: {
        title: "Événement fini",
        subtitle:
          "Vous ne pouvez pas lancer votre diaporama parce que votre événement est fini.",
      },
    },
    download: "Téléchargez Clickty",
    join: "Et rejoignez cet événement",
    findUs: "Retrouvez-nous sur l'Apple Store et le Play Store",
    seconds: "{{count}}s",
  },
  home: {
    legend: "Entrez votre code :",
    nb: "Récupérer votre code dans l'application (Paramètres -> Diaporama) et tapez le ici pour avoir accès au diaporama de votre événement.\nÇa ne marchera que si votre événement n'est pas terminé.",
  },
};
