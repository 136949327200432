import React, { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import QRCodeStyling from "qr-code-styling";

export const QrCode = ({ eventCode, width = 240, style = {} }) => {
  const ref = useRef();

  const url = useMemo(
    () => `https://api.clickty.io/event/join/${eventCode}`,
    [eventCode]
  );

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: width,
        height: width,
        data: url,
        margin: 0,
        padding: 0,
        opacity: 0.5,
        dotsOptions: { color: "#FFF" },
        hideBackgroundDots: true,
        backgroundOptions: { color: "transparent" },
      }),
    [url]
  );

  useEffect(() => {
    if (eventCode != null) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  return <div ref={ref} style={style} />;
};

QrCode.propTypes = {
  eventCode: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.any,
};
