import React from "react";
import PropTypes from "prop-types";

const LogoBlanc = ({ width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480 480"
    width={width}
    height={height}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: "#000",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "round",
        strokeDashoffset: 0,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        fill: "none",
        fillRule: "nonzero",
        opacity: 1,
      }}
      d="M118.499 267h.002"
      strokeLinecap="round"
    />
    <g>
      <path
        style={{
          stroke: "#000",
          strokeWidth: 2,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#fff",
          fillRule: "nonzero",
          opacity: 1,
        }}
        vectorEffect="non-scaling-stroke"
        transform="matrix(1.81 0 0 1.81 -338.286 -195.19)"
        d="m204.5 292 1-113 24-17h38l9-22 59 1 13 22 34 2 19 19v108l-23 24-27 2-3 30-50-31-70-1-24-24"
        strokeLinecap="round"
      />
      <g>
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#3d474a",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="M106 182a60.13 60.13 0 1 1 60.13-60.13A60.21 60.21 0 0 1 106 182Zm0-111.7a51.54 51.54 0 1 0 51.54 51.54A51.6 51.6 0 0 0 106 70.3Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#3d474a",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="M119.37 123.27c-8.88 0-12.38-8.28-10.58-14.88a13.56 13.56 0 0 0-2.72-.27 13.72 13.72 0 1 0 13.58 15.12c-.1 0-.18.03-.28.03Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#d4e27f",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="m96.48 75.52-3.16 30.06 43.27-19.67a47.61 47.61 0 0 0-40.11-10.39Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#fade79",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.872 21.105)"
          d="M90.27 77c-1.29.47-2.58 1-3.87 1.55A47.24 47.24 0 0 0 61 107l24.77 17.76Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#f4786c",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.087)"
          d="m70.84 153.46 27.61-12.54L59.46 113a46.84 46.84 0 0 0-.83 8.79v.06a47 47 0 0 0 4.23 19.47 47.88 47.88 0 0 0 8 12.15Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#d594c1",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.087)"
          d="M121.3 166.55c1.44-.5 2.89-1.06 4.29-1.7a47.23 47.23 0 0 0 25.69-29.3l-24.91-17.13Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#f69969",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="m115.36 168.15 3.18-30.06-43.27 19.67a47.54 47.54 0 0 0 40.09 10.39Z"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#4bc2d9",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="m152.71 129.59-38.95-26.77 27.64-12.57a47.55 47.55 0 0 1 7.72 11.88 47 47 0 0 1 3.59 27.46Z"
        />
        <path
          style={{
            stroke: "#3d474a",
            strokeWidth: 12,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 8,
            fill: "none",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 16.474 21.105)"
          d="M6 77a34.23 34.23 0 0 1 34.12-34.32l27.62-.44L77 21.84h62.62l10.14 20.84h22.12A34.23 34.23 0 0 1 206 77v85.58a34.22 34.22 0 0 1-34.12 34.32l-19.88-.32c-.14 12.42.15 24.84 0 37.26l-52.51-37.26-59.37.32A34.22 34.22 0 0 1 6 162.58Z"
          strokeLinecap="round"
        />
        <path
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "#3d474a",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="M166.18 73.75a12 12 0 1 1 8.95 9 12 12 0 0 1-8.95-9Z"
        />
        <path
          style={{
            stroke: "#3d474a",
            strokeWidth: 6,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "none",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="M221.88 71a44 44 0 0 0-44-44"
          strokeLinecap="round"
        />
        <path
          style={{
            stroke: "#3d474a",
            strokeWidth: 6,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "none",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="M233.88 71a56 56 0 0 0-56-56"
          strokeLinecap="round"
        />
        <path
          style={{
            stroke: "#3d474a",
            strokeWidth: 6,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "none",
            fillRule: "evenodd",
            opacity: 1,
          }}
          transform="matrix(1.81 0 0 1.81 12.854 21.105)"
          d="M245.88 71a68 68 0 0 0-68-68"
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);

LogoBlanc.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LogoBlanc;
