export default {
  appTitle: "Clickty",
  404: {
    title: "404 Error",
    cannotLaunch: "Could not launch :(",
    notFound: "Page Not Found - lets take you",
    back: "BACK",
  },
  diaporama: {
    noData: "There is no photo for the moment",
    badNetwork:
      "The internet connection of at least one screen is not optimal. The photo delay will be adjusted to offer you the best possible experience.",
    errors: {
      NOT_FOUND: {
        title: "Event not found",
        subtitle:
          "We can't find your event, maybe you made a mistake in your code or the event is finished.",
      },
      FINISHED: {
        title: "Event finished",
        subtitle:
          "You can't launch your slideshow because your event is finished.",
      },
    },
    download: "Download Clickty",
    join: "And join this event",
    findUs: "Find us on Google and Apple store",
    seconds: "{{count}}s",
  },
  home: {
    legend: "Enter your code :",
    nb: "Get the code in your app (Settings -> Slideshow) and write it here to access your event slideshow.\nIt will only work if your event is not finished.",
  },
};
