import React from "react";
import PropTypes from "prop-types";

const Next = ({ width, height, color, ...props }) => (
  <svg
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 310.285 310.285"
    width={width}
    height={height}
    {...props}
  >
    <path
      fill={color}
      d="M155.143 0C69.597 0 0 69.596 0 155.142s69.597 155.143 155.143 155.143 155.143-69.597 155.143-155.143S240.689 0 155.143 0zm81.294 167.19-65.64 42.901c-10.123 6.629-18.655 2.152-18.655-9.948v-21.171l-47.059 31.119c-10.123 6.629-17.941 2.152-17.941-9.948v-90c0-12.1 7.818-16.577 17.942-9.949l47.058 31.114v-21.165c0-12.1 8.532-16.577 18.655-9.949l65.763 42.894c10.124 6.627 10 17.473-.123 24.102z"
    />
  </svg>
);

Next.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Next;
