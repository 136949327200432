import React from "react";
import PropTypes from "prop-types";

const Back = ({ width, height, color, ...props }) => (
  <svg
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 455 455"
    width={width}
    height={height}
    {...props}
  >
    <path
      fill={color}
      d="M227.5 0C101.855 0 0 101.855 0 227.5S101.855 455 227.5 455 455 353.145 455 227.5 353.145 0 227.5 0zm102.158 321.575-81.438-47.038v47.038L85.342 227.5l162.877-94.075v47.038l81.438-47.038v188.15z"
    />
  </svg>
);

Back.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Back;
