import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const FullscreenToggle = ({ onFullscreenChange, style }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const imgFillAbsoluteDiv = {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const fullscreenActive = !!document.fullscreenElement;
      setIsFullscreen(fullscreenActive);
      if (onFullscreenChange) {
        onFullscreenChange(fullscreenActive);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [onFullscreenChange]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Erreur lors du passage en plein écran: ${err.message}`);
      });
    } else {
      document.exitFullscreen().catch((err) => {
        console.error(
          `Erreur lors de la sortie du plein écran: ${err.message}`
        );
      });
    }
  };

  return (
    <div
      onClick={toggleFullScreen}
      style={style}
      aria-label={
        isFullscreen ? "Quitter le plein écran" : "Passer en plein écran"
      }
    >
      <img
        style={imgFillAbsoluteDiv}
        src={isFullscreen ? "exitFullscreen.png" : "enterFullscreen.png"}
        alt={isFullscreen ? "Quitter le plein écran" : "Passer en plein écran"}
      />
    </div>
  );
};

FullscreenToggle.propTypes = {
  onFullscreenChange: PropTypes.func,
  style: PropTypes.string,
};

export default FullscreenToggle;
