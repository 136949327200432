import React, { useCallback, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "i18next";

import Logo from "../icons/LogoBlanc";

import "../css/Home.css";

const NBR_CHAR = 5;

function Home() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const inputRefs = useRef(Array(NBR_CHAR).fill(null));

  const [codes, setCodes] = useState(Array(NBR_CHAR).fill(""));

  const onPress = useCallback(() => {
    if (codes.filter((x) => x).join("").length === NBR_CHAR) {
      navigate(`/diaporama/${codes.join("")}`);
    }
  }, [codes, navigate]);

  const onChange = useCallback((val, currentVal, codeIndex) => {
    if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ""].includes(val)) {
      return;
    }
    const newVal = val?.[val.length - 1] ?? "";
    setCodes((prev) => prev.map((x, ind) => (ind === codeIndex ? newVal : x)));
    if (codeIndex < NBR_CHAR - 1 && newVal) {
      inputRefs.current[codeIndex + 1].focus();
    } else if (!newVal && !currentVal && codeIndex > 0) {
      inputRefs.current[codeIndex - 1].focus();
    }
  }, []);

  const onKeyDown = useCallback(
    (keyPressed, currentVal, codeIndex) => {
      if (codeIndex > 0 && currentVal === "" && keyPressed === "Backspace") {
        setTimeout(() => inputRefs.current[codeIndex - 1].focus(), 100);
      } else if (keyPressed === "Enter" && codeIndex === NBR_CHAR - 1) {
        onPress();
      }
    },
    [onPress]
  );

  const onPressLanguage = useCallback((lng) => {
    changeLanguage(lng);
  }, []);

  const onPasteEvent = useCallback((e, i) => {
    if (
      e.clipboardData.types?.length > 0 &&
      /^[0-9]*$/.test(e.clipboardData.getData("text")) &&
      i === 0
    ) {
      setCodes(
        Array(NBR_CHAR)
          .fill("")
          .map((_, i) => e.clipboardData.getData("text")?.[i] ?? "")
      );
      inputRefs.current[NBR_CHAR - 1].focus();
    }
    e.preventDefault();
  }, []);

  return (
    <div className="s006">
      <Dropdown className="dropdown">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {i18n.languages[0].includes("fr") ? "FR" : "EN"} -{" "}
          {i18n.languages[0].includes("fr") ? "🇫🇷" : "🇺🇸"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onPressLanguage("fr")}>
            Français
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onPressLanguage("en")}>
            English
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <form>
        <fieldset style={{ justifyContent: "center" }}>
          <div className="logoContainer">
            <Logo height={150} width={150} />
          </div>
          <legend>{t("home.legend")}</legend>
          <div className="code-container">
            {codes.map((code, i) => (
              <input
                ref={(ref) => (inputRefs.current[i] = ref)}
                key={i}
                className="code"
                type="number"
                placeholder="0"
                value={code}
                onChange={(e) => onChange(e.target.value, code, i)}
                onKeyDown={(e) => onKeyDown(e.key, code, i)}
                onPaste={(e) => onPasteEvent(e, i)}
                required
                inputMode="numeric"
              />
            ))}
            <button
              className="btn-search"
              type="button"
              disabled={codes.filter((x) => x).join("").length !== NBR_CHAR}
              onClick={onPress}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </button>
          </div>
          <p className="nb">{t("home.nb")}</p>
        </fieldset>
      </form>
    </div>
  );
}

export default Home;
