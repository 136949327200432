import "../css/Diaporama.css";
import "react-toastify/dist/ReactToastify.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast, ToastContainer } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Lottie from "lottie-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import io from "socket.io-client";
import { animated, useSpring } from "@react-spring/web";
import { Animate } from "react-simple-animate";
import PropTypes from "prop-types";

import { CommentToast, LikeToast } from "../components/Toasts";
import GLSlideshow from "../GLSlideshow";
import DownloadJoinIncentive from "../components/DownloadJoinIncentive";
import SwitchMode from "../components/SwitchMode";
import Play from "../icons/Play";
import Pause from "../icons/Pause";
import Back from "../icons/Back";
import Next from "../icons/Next";
import CommentSlash from "../icons/CommentSlash";
import Comment from "../icons/Comment";
import HeartSlash from "../icons/HeartSlash";
import Heart from "../icons/Heart";
import NoNetwork from "../icons/NoNetwork";
import Screen from "../icons/Screen";
import FullscreenToggle from "../components/FullscreenToggle";

function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// ButterflyScrawler
// RotateScaleFade
// CrossWarp
// Doorway

const ANIMATIONS = {
  1: "crossZoom",
  2: "directionalWipe",
  3: "wind",
  4: "WindowSlice",
  5: "ripple",
  6: "pageCurl",
};

const IconWrapper = ({ IconComponent, style, time }) => {
  const { t } = useTranslation();
  const size =
    window.innerWidth < 1000 ? 200 : window.innerWidth < 700 ? 100 : 300;
  const iconSize =
    window.innerWidth < 1000 ? 115 : window.innerWidth < 700 ? 60 : 175;
  const fontSize =
    window.innerWidth < 1000 ? 30 : window.innerWidth < 700 ? 25 : 35;
  const textBottom =
    window.innerWidth < 1000 ? 70 : window.innerWidth < 700 ? 36 : 115;
  return (
    <div
      className="icon-wrapper"
      style={{ ...style, width: size, height: size }}
    >
      <div style={{ height: iconSize, width: iconSize }}>
        <IconComponent height={iconSize} width={iconSize} />
        {Boolean(time) && (
          <p
            style={{
              position: "absolute",
              width: iconSize,
              bottom: textBottom,
              textAlign: "center",
              fontSize,
            }}
          >
            {t("diaporama.seconds", { count: time })}
          </p>
        )}
      </div>
    </div>
  );
};

IconWrapper.propTypes = {
  IconComponent: PropTypes.any,
  style: PropTypes.any,
  time: PropTypes.number,
};

function Diaporama() {
  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const slideshow = useRef();
  const prevState = useRef(null);
  const currentPhotoIdRef = useRef(null);
  const socket = useRef(null);

  const [isEmpty, setIsEmpty] = useState(false);
  const [pictureLoaded, setPictureLoaded] = useState(false);
  const [pinCode, setPinCode] = useState(null);
  const [eventInfo, setEventInfo] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [animState, setAnimState] = useState(false);
  const [animType, setAnimType] = useState(null);
  const [imageTxt, setImageTxt] = useState(null);
  const [currentPhotoId, setCurrentPhotoId] = useState(null);
  const [isBadNetwork, setIsBadNetwork] = useState(false);

  const [styles, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0 },
  }));

  useEffect(() => {
    const container = document.getElementById("canvasContainer");
    window.addEventListener("resize", () =>
      slideshow.current?.setSize(
        Math.min(container.clientWidth, (container.clientHeight * 16) / 9),
        Math.min((container.clientWidth * 9) / 16, container.clientHeight)
      )
    );
  }, []);

  useEffect(() => {
    socket.current?.emit("fb-screen", {
      event: "PRE-LOAD-OK",
      id: currentPhotoId,
      eventId: eventInfo?.eventId,
      test: "test",
    });
  }, [currentPhotoId, eventInfo]);

  const onNewImage = useCallback(
    (photo) => {
      api.start({ opacity: 0, scale: 0 });
      setIsEmpty(false);
      setImageTxt(photo.text);
      setCurrentPhotoId(photo.id);
      currentPhotoIdRef.current = photo.id;
      if (!slideshow.current) {
        setPictureLoaded(true);
        const container = document.getElementById("canvasContainer");
        slideshow.current = new GLSlideshow([photo.url], {
          canvas: document.getElementById("myCanvas"), // optional
          width: Math.min(
            container.clientWidth,
            (container.clientHeight * 16) / 9
          ),
          height: Math.min(
            (container.clientWidth * 9) / 16,
            container.clientHeight
          ),
          imageAspect: 16 / 9,
          // duration: 1000, // optional
          interval: 100000000000000000000, // optional
          effect: "crossZoom", // optional
        });
      } else {
        slideshow.current.setEffect(ANIMATIONS[getRandomNumberBetween(1, 6)]);
        slideshow.current.insert(
          photo.url,
          slideshow.current.currentIndex + 1,
          () => slideshow.current.to(slideshow.current.currentIndex + 1)
        );
      }
      if (photo.text) {
        setTimeout(() => api.start({ opacity: 1, scale: 1 }), 500);
      }
    },
    [pictureLoaded]
  );

  const onNewComment = useCallback((comment) => {
    toast(<CommentToast commentImage={comment} />, {
      position: "bottom-left",
    });
  }, []);

  const onNewLike = useCallback((like) => {
    toast(<LikeToast likeImage={like} />, {
      style: { width: 110 },
    });
  }, []);

  const onEventInfo = useCallback((event) => {
    setEventInfo(event);
    setPinCode(event?.shortCode);
  }, []);

  const onNoPhoto = useCallback(() => setIsEmpty(true), []);

  const onError = useCallback(
    (type) => () => {
      setErrorType(type);
      setTimeout(() => navigate("/"), 10000);
    },
    []
  );

  const onStateChanged = useCallback((newState) => {
    switch (newState.latestUserAction) {
      case "PLAY_STOP":
        setAnimType(newState.state);
        break;
      case "NEXT":
      case "BACK":
        setAnimType(newState.latestUserAction);
        break;
      case "UPDATE_CONFIG":
        if (!prevState.current) {
          setAnimType("PLAY");
        } else if (newState.displayLike !== prevState.current.displayLike) {
          setAnimType(newState.displayLike ? "SHOW_LIKE" : "HIDE_LIKE");
        } else if (
          newState.displayComment !== prevState.current.displayComment
        ) {
          setAnimType(
            newState.displayComment ? "SHOW_COMMENT" : "HIDE_COMMENT"
          );
        } else {
          setAnimType("TIME");
        }
    }
    prevState.current = newState;
    setTimeout(() => setAnimState(true), 0);
  }, []);

  useEffect(() => {
    const host =
      location.hostname === "localhost"
        ? "https://slideshow.clickty.synovia.fr"
        : window.location.host;
    socket.current = io(`${host}/events?code=${params.code}`, {
      transports: ["websocket"],
      reconnection: true,
    });
    socket.current.on("network_update", setIsBadNetwork);
    socket.current.on("next_photo", onNewImage);
    socket.current.on("photo_comment", onNewComment);
    socket.current.on("photo_like", onNewLike);
    socket.current.on("not_photo_wait", onNoPhoto);
    socket.current.on("event_info", onEventInfo);
    socket.current.on("event_state_update", onStateChanged);
    socket.current.on("event_not_found", onError("NOT_FOUND"));
    socket.current.on("event_code_event_date_error", onError("FINISHED"));
    socket.current.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.current.connect();
      }
      // else the socket will automatically try to reconnect
    });

    return () => {
      socket.current.disconnect();
      socket.current = null;
    };
  }, [params.code]);

  const renderAnim = useCallback((style) => {
    const size =
      window.innerWidth < 1000 ? 200 : window.innerWidth < 700 ? 100 : 300;
    switch (animType) {
      case "PLAY":
        return <Play color="white" height={size} width={size} style={style} />;
      case "PAUSE":
        return <Pause color="white" height={size} width={size} style={style} />;
      case "BACK":
        return <Back color="white" height={size} width={size} style={style} />;
      case "NEXT":
        return <Next color="white" height={size} width={size} style={style} />;
      case "HIDE_COMMENT":
        return <IconWrapper IconComponent={CommentSlash} style={style} />;
      case "SHOW_COMMENT":
        return <IconWrapper IconComponent={Comment} style={style} />;
      case "HIDE_LIKE":
        return <IconWrapper IconComponent={HeartSlash} style={style} />;
      case "SHOW_LIKE":
        return <IconWrapper IconComponent={Heart} style={style} />;
      case "TIME":
        return (
          <IconWrapper
            IconComponent={Screen}
            style={style}
            time={prevState.current?.photoDisplayInSeconds}
          />
        );
      default:
        return null;
    }
  });

  const renderTooltip = useCallback(
    (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {t("diaporama.badNetwork")}
      </Tooltip>
    ),
    [t]
  );

  const Content = useMemo(() => {
    if (errorType) {
      return (
        <div className="errorContainer">
          <h1>{t(`diaporama.errors.${errorType}.title`)}</h1>
          <h2>{t(`diaporama.errors.${errorType}.subtitle`)}</h2>
        </div>
      );
    }

    if (!isEmpty && !pictureLoaded) {
      return (
        <div className="loading-container">
          <Lottie
            animationData={require("../images/lottie/loading.json")}
            style={{ width: "70%" }}
          />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <div className="loading-container">
          <Lottie
            animationData={require("../images/lottie/image-loading.json")}
            style={{ width: "30%" }}
          />
          <h1 style={{ textAlign: "center", color: "white" }}>
            {t("diaporama.noData")}
          </h1>
        </div>
      );
    }

    return (
      <>
        <ToastContainer autoClose={3000} closeButton={false} />
        <div className="animationContainer">
          <Animate
            play={animState}
            duration={0.6}
            start={{ opacity: 0, transform: "scale(0)" }}
            end={{ opacity: 1, transform: "scale(1)" }}
            complete={{ opacity: 0, transform: "scale(0)" }}
            onComplete={() => setAnimState(false)}
            render={({ style }) => renderAnim(style)}
          />
        </div>
        {window.innerWidth > 1000 && pinCode && (
          <DownloadJoinIncentive eventCode={pinCode} />
        )}

        <SwitchMode
          code={params.code}
          mode="photoWall"
          style={{
            position: "absolute",
            width: `6vh`,
            height: `6vh`,
            top: `2vh`,
            right: `5vh`,
            opacity: "0.7",
            cursor: "pointer",
          }}
        />

        <FullscreenToggle
          style={{
            position: "absolute",
            width: `4vh`,
            height: `4vh`,
            top: `3vh`,
            right: `12vh`,
            opacity: "0.7",
            cursor: "pointer",
            zIndex: 1000,
          }}
          onFullscreenChange={() => {}}
        />
      </>
    );
  }, [errorType, pictureLoaded, isEmpty, animState, pinCode, renderAnim]);

  return (
    <div className="App">
      <div className="canvas-container" id="canvasContainer">
        <canvas id="myCanvas"></canvas>
        <animated.div className="Comment-Box" style={styles}>
          {imageTxt}
        </animated.div>
      </div>
      {Content}
      {isBadNetwork && (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <button className="overlay-button">
            <NoNetwork width={31} height={30} color="white" />
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
}

export default Diaporama;
