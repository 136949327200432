import React from "react";
import PropTypes from "prop-types";

const Pause = ({ width, height, color, ...props }) => (
  <svg
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45.812 45.812"
    width={width}
    height={height}
    {...props}
  >
    <path
      fill={color}
      d="M39.104 6.708c-8.946-8.943-23.449-8.946-32.395 0-8.946 8.944-8.946 23.447 0 32.394 8.944 8.946 23.449 8.946 32.395 0 8.943-8.946 8.943-23.449 0-32.394zM20.051 31.704a2.64 2.64 0 0 1-5.281 0V14.108a2.64 2.64 0 0 1 5.281 0v17.596zm10.99 0a2.64 2.64 0 0 1-5.28 0V14.108c0-1.457 1.183-2.64 2.64-2.64s2.64 1.183 2.64 2.64v17.596z"
    />
  </svg>
);

Pause.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Pause;
